import { ProfileLayout } from '../../types';
import stylesParams from '../../components/ProfileCard/stylesParams';

export const PROFILE_LAYOUT_PARAM_KEY = stylesParams.profileLayout.key!;

export const SANTA_MEMBERS_APP_DEF_ID = '14cc59bc-f0b7-15b8-e1c7-89ce41d0e0c9';

export const PROFILE_WIDGET_HORIZONTAL_LAYOUT_STYLE_PARAM = {
  type: 'number' as const,
  key: PROFILE_LAYOUT_PARAM_KEY,
  param: {
    value: ProfileLayout.FullWidth as number,
  },
};

export const PROFILE_WIDGET_SIDEBAR_LAYOUT_STYLE_PARAM = {
  type: 'number' as const,
  key: PROFILE_LAYOUT_PARAM_KEY,
  param: {
    value: ProfileLayout.Card as number,
  },
};
